import React, { Component } from 'react';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";     //     useParams?
import axios from 'axios';

import './w3.css';


// from Architect template on https://www.w3schools.com/w3css/w3css_templates.asp
// must remove comments and close elements (e.g., img)

// also change /w3-images/filename.jpg to just filename.jpg

class Navbar extends Component {
    render() {
        if (this.props.onMain) {
            return (
                <div className="w3-top">
                  <div className="w3-bar w3-white w3-wide w3-padding w3-card">
                    <Link className="w3-button w3-clear" to="/"> 
                      <b>Socratecha</b> Publishing
                    </Link>
                    <div className="w3-right w3-hide-small">
                      <a href="/#books" className="w3-bar-item w3-button">Books</a>
                      <a href="/#about" className="w3-bar-item w3-button">About</a>
                      <a href="/#contact" className="w3-bar-item w3-button">Contact</a>
                    </div>
                  </div>
                </div>
            );
        } else {
            return (
                <div className="w3-top">
                  <div className="w3-bar w3-white w3-wide w3-padding w3-card">
                    <Link className="w3-button w3-clear" to="/"> 
                      <b>Socratecha</b> Publishing
                    </Link>
                    <div className="w3-right w3-hide-small">
                      <Link to="/#books" className="w3-bar-item w3-button">Books</Link>
                      <Link to="/#about" className="w3-bar-item w3-button">About</Link>
                      <Link to="/#contact" className="w3-bar-item w3-button">Contact</Link>
                    </div>
                  </div>
                </div>
            );
        }
    }
}


const Header2 = styled.header`
   max-width:750px;
   max-height:500px;
`;


class Header extends Component {
    render() {
        return (
            <Header2
              className="w3-display-container w3-content w3-wide"
              id="home">
              <img className="w3-image"
                   src="library-shallow.jpg"
                   alt="Library" width="1500"
                   height="800"/>
              <div className="w3-display-middle w3-margin-top w3-center">
                <h1 className="w3-xxlarge w3-text-white">
                  <span className="w3-padding w3-black w3-opacity-min">
                    <b>Socratecha</b>
                  </span>
                  <span className="w3-hide-small w3-text-light-grey">
                    Publishing
                  </span>
                </h1>
              </div>
            </Header2>
        );
    }
}


const Img100 = styled.img`
    width:99%;
`;


class Project extends Component {
    constructor(props) {
        super(props);
        
        const { href, src, alt } = this.props;
        this.theLink = (
            href.startsWith('http') ? (
                <a
                  href={ href }
                  className="w3-button w3-clear w3-block">
                  <Img100 src={ src } alt={ alt }/>
                </a>
            ) : (
                <Link to={ href } className="w3-button w3-clear w3-block">
                  <Img100 src={ src } alt={ alt }/>
                </Link>
            )
        );
    }
    
    render() {
        return (
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">
                  { this.props.children }
                </div>
                { this.theLink }
              </div>
            </div>
        );
    }
}


class ProjectRow extends Component {
    render() {
        const props = this.props;
        return <div className="w3-row-padding">{ props.children }</div>;
    }
}


function foldList(list, perRow) {
    const folded = [ [] ];

    list.forEach(item => {
        if (folded[folded.length - 1].length === perRow) {
            folded.push([]);
        }
        folded[ folded.length - 1].push(item);
    });

    return folded;
}


class ProjectSection extends Component {
    constructor(props) {
        super(props);
        
        this.newBooks = props.books.map(
            (pic, idx) => {
                return <Project key={idx} src={pic.src} alt={pic.alt} href={pic.href}>
                         {pic.words}
                       </Project>;
            }
        );
        this.picRows = foldList(this.newBooks, 4).map(
            (aRow, idx) => { return <ProjectRow key={idx}>{ aRow }</ProjectRow> }
        );
    }
    
    render() {
        const picRows = this.picRows;
        const { headerText } = this.props;
        
        return (
            <>
              <div className="w3-container w3-padding-32" id="books">
                <h2 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                  { headerText }
                </h2>
              </div>

              { picRows }
            </>
        );
    }
}


class Portrait extends Component {
    render() {
        const { src, alt, name, title, description } =
              this.props.person;
        return (
            <div className="w3-col l3 m6 w3-margin-bottom">
              <Img100 src={ src } alt={ alt }/>
              <h3>{ name }</h3>
              <p className="w3-opacity">{ title }</p>
              { description }
            </div>
        );
    }
}


const Div1564 = styled.div`
    max-width:1564px;
`;


class AboutSection extends Component {
    render() {
        const { folks } = this.props;
        
        return (
            <>
              <div className="w3-container w3-padding-32" id="about">
                <h2 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                  About
                </h2>
                <p>
                  Socratecha, started in 2019, is an agile publishing company that
                  approaches technical publications as a form of software development.
                  We believe books should not only be authored but programmed.  We
                  produce and maintain our publishing products using both open source and
                  custom technologies: LaTeX with the Memoir package for layout;
                  Emacs with org-mode for authoring; git for version control;
                  bookmaker, a custom-written tool, to support authoring, testing,
                  debugging, and compiling books; make to manage the book compilation
                  process; and Amazon KDP to provide agile publication delivery.
                </p>
              </div>

              <div className="w3-row-padding">
                { folks.map( (person, idx) =>
                             <Portrait key={idx} person={person}/> ) }
              </div>
            </>
        );
    }
}


class ContactSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendTo : 'editors',
            name : '',
            email : '',
            subject : '',
            comment : ''
        };

        this.updateByValue = this.updateByValue.bind(this);
        this.newHandler = this.wrapHandleForm(this.props.handleForm);
    }

    updateByValue(event) {
        const { name, value } = event.target;
        let update = {};
        update[name] = value;
        this.setState(update);
    }

    wrapHandleForm(origFunction) {
        return function(event) {
            event.preventDefault();
            origFunction(this.state);
        }.bind(this);
    }
    
    render() {
        return (
            <div className="w3-container w3-padding-32" id="contact">
              <h2 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                Contact
              </h2>
              <p>Lets get in touch and talk about your next project.</p>
              <form action="/action_page.php" target="_blank">
                <select
                  className="w3-input w3-border"
                  name="sendTo"
                  value={ this.state.sendTo }
                  onChange={ this.updateByValue }>
                  <option value="editors">
                    Email the editors at Socratecha Publishing
                  </option>
                  <option value="help">
                    Email the technical help desk at Socratecha Publishing</option>
                  <option value="btw">
                    Email the "by-the-way" coordinator at Socratecha Publishing</option>
                </select>
                <input
                  className="w3-input w3-section w3-border"
                  type="text"
                  value={ this.state.name }
                  onChange= {this.updateByValue }
                  placeholder="Name"
                  required name="name"/>
                <input
                  className="w3-input w3-section w3-border"
                  type="text"
                  value={ this.state.email }
                  onChange={ this.updateByValue }
                  placeholder="Your Email"
                  required name="email"/>
                <input
                  className="w3-input w3-section w3-border"
                  type="text"
                  value={ this.state.subject }
                  onChange={ this.updateByValue }
                  placeholder="Subject"
                  required name="subject"/>
                <input
                  className="w3-input w3-section w3-border"
                  type="text"
                  value={ this.state.comment }
                  onChange={ this.updateByValue }
                  placeholder="Comment"
                  required name="comment"/>
                <button
                  onClick={ this.newHandler }
                  className="w3-button w3-black w3-section"
                  type="submit">
                  <i className="fa fa-paper-plane"></i> SEND MESSAGE
                </button>
              </form>
            </div>
        );
    }
}


class FrapBookPage extends Component {
    render() {
        const { links } = this.props;
        return (
            <>
              <div className="w3-container w3-padding-32" id="about">
                <h2 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                  Quick Start Full Stack Web Development
                </h2>
                <h3 className="w3-border-light-grey w3-padding-16">
                  Build Secure Asynchronous Single-Page Apps with Flask, React, and PostgreSQL
                </h3>
                <h4 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                  by Erik M. Ferragut
                </h4>
                <p>
                  Quick Start Full Stack Web Development removes the trial and error from learning to make web applications.  Being a full stack web developer does not mean knowing everything about every web technology,but rather knowing enough to build a complete application including a front end, a back end, and a database.  Web searching can provide useful snippets of information, but integrating those pieces into a working whole remains a challenge.  This book will walk the reader through both the component technologies and the steps required to get the pieces to work together.  This clear focus can save countless hours of frustration compared to trying to assemble a working solution from inconsistent and outdated sources.
                </p>
                <p>
                  The reader should have some familiarity with Python or JavaScript, but no web programming experience is assumed.  Quick Start Full Stack Web Development explains key concepts, such as REST APIs and JSON Web Tokens, and then puts these concepts into practice with real, working examples.  The examples are built step-by-step, providing an opportunity to experiment with the ideas.  Furthermore, there is a consistent focus on getting instant feedback as changes are made to the code, a good practice for quickly building intuition and gaining experience.
                </p>
                <p>
                  The chosen technologies (React, Flask, and PostgreSQL) are excellent options for newcomers to web development because they are relatively easy to learn, have vibrant supportive communities, and can scale to large and complex applications.  Rather than providing a cursory introduction to a variety of technology options, Quick Start Full Stack Web Development provides a thorough foundation in one technology stack.  This prevents confusion, provides more opportunities to reinforce concepts, and leads more quickly to significant results.
                </p>
                <p>
                  Learn how to:
                </p>
                <ul>
                  <li>Build a Python Flask REST API</li>
                  <li>Develop and style a React client</li>
                  <li>Design SQLite and PostgreSQL databases using SQLAlchemy</li>
                  <li>Incorporate JSON Web Tokens (JWT) for authentication</li>
                  <li>Test it using httpie, browser dev tools, pytest, and Jest</li>
                  <li>Document it using Sphinx and Storybook</li>
                  <li>Deploy using Gunicorn and NGINX on a Platform-as-a-Service</li>
                </ul>
                <p>
                  The result is a fully functional full stack web application that addresses all the little details, like serving the client and API from the same server, managing the environment with a config file, making the documentation visible in the git repository, and populating and uploading databases.
                </p>
                <p>
                  Because it focuses on getting to a working application, Quick Start Full Stack Web Development is well suited to entrepreneurs and solopreneurs building out their minimal viable products.  And because it explains the concepts and shows them in practice, it will help programmers who want to get into web development.  <em>If you want to learn these powerful skills as quickly as possible, then this book is for you.</em>
                </p>

                <ProjectSection headerText="Useful Links" books={ links }/>
              </div>
              <div className="w3-container w3-padding-32" id="books">
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">
                  Errors and Updates
                </h3>
                <p>
                  No errors have been found yet.  Please report any errors or
                  important omissions to <a href="/#about">the editors</a>.
                </p>
              </div>
            </>
        );
    }
}


const PopupDiv = styled.div`
  position: fixed;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: auto;
  margin: auto;
  background: lightgray;
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 20px;
`;


// TODO
// ----
// 1. Make it contact the server, record the request, and send an email
// 2. add video/audio/etc. links to the book page?
// 3. update with errata, mentions, planned updates, link to blog???
// 4. Show the versions info - how v1.0 uses what; changelog

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { showPopup : false,
                       popupContent: null,
                       wasSent : {
                           sendTo : 'editors',
                           name : '',
                           email : '',
                           subject : '',
                           comment : ''
                       }
                     };
        
        this.bookData = [
            { src:"front-small.jpg", alt:"Full Stack", words:"Full Stack",
              href:"/frap-book" }
        ];
        
        this.folksData = [
            {   src:"editors500.jpg", alt:"Editors",
                name:"Editors", title:"editors (at) socratecha.com",
                description:<p>Bulk purchase, media inquiries, discovered errors,
                              and book ideas.</p>
            },
            {   src:"ithelp.jpg", alt:"Help",
                name:"Help", title:"help (at) socratecha.com",
                description:<p>Technical help with in-text examples for any of our
                              books on any platform.</p>
            },
            {   src:"btw.jpg", alt:"By-the-Way",
                name:"By-the-Way", title:"btw (at) socratecha.com",
                description:<p>Stuff you want to share, including opinions,
                              positive and negative.</p>
            },
        ];

        this.frapBookLinks = [
            {   src:"Octocat.jpg", alt:"Get Code",
                words:"Get Code from GitHub",
                href:"https://github.com/socratecha",
            },
            {   src:"front-small.jpg", alt:"Amazon",
                words:"Buy Paperback from Amazon",
                href:"http://amzn.com/B0849YXBS2"
            },
            {   src:"front-small.jpg", alt:"Apple iTunes",
                words:<>Buy EPUB from iTunes<br/>(Coming Soon!)</>,   // EPUB
                href:"#"
            },
            {   src:"front-small.jpg", alt:"Google Play",
                words:"Buy PDF from Google Play",
                href:"https://play.google.com/store/books/details?id=qdvNDwAAQBAJ",
            },
        ];
        
        this.mapURL = "map.jpg";

        this.footerLine1 = (
            <>Based on a&nbsp;                  
              <a href="https://www.w3schools.com/w3css/w3css_templates.asp"
                 title="free template"
                 className="w3-hover-text-green">
                free W3 template
              </a> powered by&nbsp;
              <a href="https://www.w3schools.com/w3css/default.asp"
                 title="W3.CSS"
                 className="w3-hover-text-green">
                w3.css
              </a> and converted to the&nbsp;
              <a href="https://reactjs.org"
                 title="React"
                 className="w3-hover-text-green">
                React framework
              </a>.</>);

        this.footerLine2 = (
            <>
              Images by&nbsp;
              <a
                className="w3-hover-text-green"                   
                href="https://pixabay.com/users/TuendeBede-3187213/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2507902">
                TuendeBede
              </a>,&nbsp;
              <a
                href="https://pixabay.com/users/quinntheislander-1139623/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1351629">
                Quinn Kampschroer
              </a>,&nbsp;
              <a
                href="https://pixabay.com/photos/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1245714">
                Free-Photos
              </a>, and&nbsp;
              <a
                href="https://pixabay.com/users/742680-742680/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=620316">
                yogesh more
              </a> from&nbsp;
              <a
                className="w3-hover-text-green"                   
                href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=620316,1245714,1351629,2507902">Pixabay
              </a>.
            </>
        );
        
        this.footerBothLines = (
            <footer
              className="w3-center w3-black w3-padding-16">
              <p>{ this.footerLine1}<br/>{ this.footerLine2 }</p>
            </footer>
        );

        this.footerFirstLine = (
            <footer
              className="w3-center w3-black w3-padding-16">
              <p>{ this.footerLine1}</p>
            </footer>
        );

        this.togglePopup = this.togglePopup.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }

    
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }


    handleForm(theState) {
        const closePopup = this.togglePopup;
        
        if ((theState.name === '') ||
            (theState.email === '') ||
            (theState.comment === '') ||
            (theState.subject === '')) {
            this.setState({
                showPopup : true,
                popupContent : (
                    <div className='popup'>
                      <PopupDiv>
                        <h2>Please fill every box.</h2>
                        <button onClick={ closePopup }>[ Close ]</button>
                      </PopupDiv>
                    </div>
                )
            });
            return;
        }

        const { name, subject, comment, sendTo, email } = theState;

        this.setState({
            showPopup : true,
            popupContent : (
                <div className='popup'>
                  <PopupDiv>
                    <h1>Thank you, { name }</h1>
                    <h2>Your message has been sent...</h2>
                    <p>
                      to the {
                          sendTo === 'editors' ? "editors" : (
                              sendTo === 'help' ? "help desk"
                              : '"by-the-way" coordinator')
                      } from { email } and with subject "{ subject }".
                      The content is:
                    </p>
                    <blockquote>{ comment }</blockquote>
                    <p>
                      We appreciate your feedback!
                      Confirming receipt.  Please wait...
                    </p>
                    <button onClick={ closePopup }>[ Close ]</button>
                  </PopupDiv>
                </div>
            )
        });

        const theMessage = { sendTo, email, name, subject, comment};
        axios.post(
            'api/v1/record',
            theMessage
        ).then(
            response => {
                this.setState({
                    popupContent : (
                        <div className='popup'>
                          <PopupDiv>
                            <h1>Thank you, { name }</h1>
                            <h2>Your message</h2>
                            <p>
                              to the {
                                  sendTo === 'editors' ? "editors" : (
                                      sendTo === 'help' ? "help desk"
                                      : '"by-the-way" coordinator')
                              } from { email } and with subject "{ subject }"
                              has been received.  We read all of our email
                              and reply whenever possible.
                              We appreciate your feedback!
                            </p>
                            <button onClick={ closePopup }>[ Close ]</button>
                          </PopupDiv>
                        </div>
                    )
                });
            }
        ).catch(
            error => {
                this.setState(
                    {popupContent:
                     <div className='popup'>
                       <PopupDiv>
                         <h1>Your message</h1>
                         <h2>was not sent</h2>
                         <p>
                           Probably, there is an issue with the
                           internet connection, or else we are
                           experiencing technical difficulties on
                           our end.  You can send email directly to
                           your intended recipient:
                         </p>
                         <ul>
                           <li>editors@socratecha.com</li>
                           <li>help@socratecha.com</li>
                           <li>btw@socratecha.com</li>
                         </ul>
                         <p>
                           Sorry for the inconvenience.
                         </p>
                         <button onClick={ closePopup }>[ Close ]</button>
                       </PopupDiv>
                     </div>
                    });            
            }
        );
    }

    
    render() {
        const { bookData, folksData, mapURL, frapBookLinks,
                footerBothLines, footerFirstLine, handleForm
              } = this;

        const { showPopup, popupContent } = this.state;
        
        return (
            <Router>
              <Switch>
                <Route exact path="/">
                  <Navbar onMain />
                  <Header/>
                  <Div1564>
                    <ProjectSection headerText="Books" books={ bookData }/>
                    <AboutSection folks={ folksData }/>
                    {showPopup ? popupContent : null }
                    <ContactSection handleForm={ handleForm }/>
                    <div className="w3-container">
                      <Img100 src={ mapURL } className="w3-image"/>
                    </div>
                  </Div1564>
                  { footerBothLines }
                </Route>
                <Route path="/frap-book">
                  <Navbar onMain={false}/>
                  <Header/>
                  <Div1564>
                    <FrapBookPage links={ frapBookLinks }/>
                  </Div1564>
                  { footerFirstLine }
                </Route>
              </Switch>            
            </Router>
        );
    }
}


export default App;
